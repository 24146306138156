import React from "react";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Seo from "../../components/utils/SEO";
import "./travel-easy.scss";

const TravelEasy = () => (
  <Layout
    hideDisclaimer={true}
    hideHeader={true}
    hideFooterNavigation={true}>
    <Seo title="Airport Tips for a Better Trip" />
    <Wrapper>
      <div className="travel">
        <div className="richText">
          <h2>Airport Tips for a Better Trip</h2>
          <p>
            Almost everyone loves traveling. Being at an airport? Not so much. If you’ve ever taken
            a flight, then you’re probably familiar with the array of varying hassles that can arise
            when waiting to depart. Whether it’s long security lines, slow baggage claims, or crowds
            of people, <b>any frequent traveler or first-time</b> flyer has most likely had their
            fair share of airport inconveniences.
          </p>
          <p>
            But it doesn’t have to be this way. An airport is also a place of{" "}
            <b>mystery and excitement</b>, the final stepping stone before travel dreams come to
            fruition. With their assortment of enticing shops, luxury lounges, and captivating
            architecture, spending time at an airport can be turned into a pleasant, and oftentimes
            even enjoyable experience.
          </p>
          <p>
            This might seem like a distant fantasy for now. So to help you turn this into reality,
            we’ve compiled a <b>list of airport tips and hacks</b> that will help you enjoy airports
            more than you ever thought you could:
          </p>
          <ul className="e-list">
            <li>
              <p>
                Bring your own food and <b>pack an empty water bottle</b> to avoid the
                always-inflated airport food and beverage prices.
              </p>
            </li>
            <li>
              <p>
                Wear socks so that you avoid stepping barefoot on the dirty airport floor when
                passing through security checkpoints.
              </p>
            </li>
            <li>
              <p>
                Bring a <b>portable charger</b>, a surprising amount of airports don’t have working
                or convenient outlets.
              </p>
            </li>
            <li>
              <p>
                Book a flight in the <b>middle of the night</b> to steer clever of crowds.
              </p>
            </li>
            <li>
              <p>Take a photo of your checked luggage in case it gets lost.</p>
            </li>
            <li>
              <p>
                Take <b>hand sanitizer</b> as airports and airplanes are usually full of germs.
              </p>
            </li>
            <li>
              <p>
                Download offline maps before leaving in case there’s no Wi-Fi at your arrival
                airport.
              </p>
            </li>
            <li>
              <p>
                Take some <b>Ziplock bags</b> with you to protect electronic devices and store your
                snacks.{" "}
              </p>
            </li>
            <li>
              <p>
                Go left at security checkpoints. Studies have shown that people usually go right, so
                you may be able to skip long lines with this trick.
              </p>
            </li>
            <li>
              <p>
                Wear a comfortable outfit with <b>various layers</b> to adjust to the often-changing
                airport temperature.
              </p>
            </li>
            <li>
              <p>
                Have your electronics in an <b>easy-to-access location</b> to save time when having
                to show them at security.
              </p>
            </li>
            <li>
              <p>
                Check-in for your flight in advance to <b>avoid cues</b>, pick a good seat, and
                ensure you don’t have to pay extra for a late check-in.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  </Layout>
);

export default TravelEasy;
